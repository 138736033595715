// components/cta.js
export default function CTA() {
    return {
        // Track if the CTA button should be visible
        show: false,

        // Called automatically when the component is initialized
        init() {
            // Update `show` whenever the user scrolls
            window.addEventListener('scroll', () => {
                this.show = window.pageYOffset > 200;
            });
        },

        // Scroll to the top of the page smoothly
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
}
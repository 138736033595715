import Alpine from 'alpinejs';
import AlpineFocus from '@alpinejs/focus';
import AlpineUI from '@alpinejs/ui';
import AlpineCollapse from '@alpinejs/collapse';
import glide from './components/glide.js';
import CTA from './components/cta.js';

// Components
Alpine.data('glide', glide);
Alpine.data('cta', CTA);

// Plugins
Alpine.plugin(AlpineFocus);
Alpine.plugin(AlpineUI);
Alpine.plugin(AlpineCollapse);

// Custom Directive: Reveal on Scroll
Alpine.directive("reveal", (el, { expression }) => {
    const delay = expression ? parseInt(expression) : 0;

    const initAnimation = () => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    el.classList.add("opacity-100", "translate-y-0");
                    el.classList.remove("opacity-0", "translate-y-8");
                }, delay);
                observer.disconnect(); // Stop observing once revealed
            }
        }, { threshold: 0.2 });

        // Set the initial state
        el.classList.add("opacity-0", "translate-y-8", "transition-all", "duration-700", "ease-out");
        el.style.willChange = "transform, opacity";
        observer.observe(el);
    };

    // Wait for fonts to be loaded to avoid layout shifts during animation
    if (document.fonts && document.fonts.ready) {
        document.fonts.ready.then(initAnimation);
    } else {
        initAnimation();
    }
});
// Expose Alpine globally
window.Alpine = Alpine;

// Start Alpine after all directives & plugins are registered
Alpine.start();